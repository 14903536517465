<div id="job-banner" class="column fill-space scrollbar" *ngIf="job">

  <div class="header row fill-space">
    <div class="fill-space gap-2 row">
      <div class="row">
        <a routerLink="../" class="back flex">
          <img src="assets/images/back.svg" />
        </a>
        <div class="title center">
          <div class="job-title-label"> Job: </div>
          <div class="job-title-value"> {{ job.title }} </div>
          <div class="job-title-status">
            <div class="job-status">
              <div class="center"> <div class="job-status-circle" [class]="job.jobStatus === EJobStatus.OPEN ? 'green' : 'gray'"></div> </div>
              <div class="job-status-value center"> {{ job.jobStatus }} </div>
            </div>
          </div>
        </div>
      </div>
     <!-- <div class="gap-1">
        <div class="job-banner-button  center" (click)="showScoringGuidelines()">
          <div class="button button-action job-banner-button-white">
            <div class="button-icon"> <img src="assets/images/magic-wand.svg"/> </div>
            <div class="button-text">  Scoring Guidelines </div>
          </div>
        </div>
        <div class="job-banner-button  center" *ngIf="job.status === EStatus.COMPLETED" (click)="showAddApplicant()">
          <div class="button button-action job-banner-button-black">
            <div class="button-icon"> <img src="assets/images/plus-white.svg"/> </div>
            <div class="button-text">  Add Applicants To Job </div>
          </div>
        </div>
      </div>-->
    </div>
  </div>

  <div class="job-data row">
    <div class="job-data-item row-center-left">
      <div class="job-data-item-icon"> <img src="assets/images/date-outline.svg" /> </div>
      <div class="job-data-item-label"> Created On: </div>
      <div class="job-data-item-value">  {{ job.creationTime.toDate() | date: "mediumDate" }} </div>
    </div>
    <div class="job-data-item row-center-left">
      <div id="applicants-count-icon" class="job-data-item-icon"> <img src="assets/images/user-outline.svg" /> </div>
      <div class="job-data-item-label"> Applicants: </div>
      <div class="job-data-item-value">  {{ job.applicantsCount }} </div>
    </div>
  </div>

  <job-menu></job-menu>

</div>