<div id="jobs" class="row fill-space overflow-hidden">
  <div class="page-column column">
    <div class="header row">
      <div class="column">
        <div class="title">Filters</div>
      </div>
    </div>
    <div class="filters-content column scrollbar">

      <ng-container *ngFor="let f of filtersService.jobFilters">
        <filter-panel [filter]="f" (updateSelectedOptions)="filtersService.updateSelectedOptions($event)"></filter-panel>
      </ng-container>

    </div>
  </div>
  <div class="page-column column fill-space">
    <div class="header row">
      <div class="fill-space column">
        <div class="title">Jobs</div>
      </div>
      <div class="header-icon" (click)="showCreateJob()">
        <div class="button button-action">+ Add New Job</div>
      </div>
    </div>

    <div class="search row space-bottom-1">
      <div class="search-icon"><img src="assets/images/search.svg" /></div>
      <div class="search-input fill-space">
        <input #searchInput type="text" placeholder="Enter search keyword and press enter..." (keydown.enter)="addSearchTag(searchInput)" class="fill-space" />
      </div>
    </div>

    <div class="filter-tags row row-center-left wrap">
      <ng-container *ngFor="let f of filtersService.jobFilters">
        <tag *ngFor="let tag of f.selectedOptions" [text]="tag" [type]="f.name" (click)="filtersService.removeTag(f, tag)"></tag>
      </ng-container>
      <tag *ngFor="let tag of searchTags" [text]="tag" [type]="'Custom Search'" [className]="'custom'" (click)="removeSearchTag(tag)"></tag>
    </div>

    <div class="jobs-list column fill-space scrollbar">
      <ng-container *ngIf="filteredJobs$ | async as jobs; else loading">
        <ng-container *ngIf="jobs.length > 0; else empty">
          <ng-container *ngFor="let job of filteredJobs$ | async">
            <job-panel [job]="job"> </job-panel>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>

  </div>

</div>


<!-- No items message -->
<ng-template #empty>
  <div class="empty column center">
    <div class="empty-icon"> <img src="assets/images/empty.svg"/> </div>
    <div class="empty-title"> No Jobs  </div>
    <div class="empty-text" (click)="showCreateJob()">  <div class="button button-action"> + Add New Job </div>  </div>
  </div>
</ng-template>

<!-- Loading message -->
<ng-template #loading>
  <div class="empty column center">
    <loader></loader>
  </div>
</ng-template>