import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { Auth, UserCredential } from '@angular/fire/auth';

interface Credentials {
  email: string;
  password: string;
}

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  credentials: Credentials = { email: '', password: '' };
  error: string | null = null;
  isLoading: boolean = false;
  slides: string[] = ["slide-1.png", "slide-2.png", "slide-3.png", "slide-4.png"];
  errorMessages: { [key: string]: string } = {
    'auth/invalid-email': 'Wrong Username Or Password!',
    'auth/wrong-password': 'Wrong Username Or Password!',
    'auth/internal-error': 'Problem With Authentication Server. Please contact support at support@hirefox.ai'
  };

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  signInWithEmail(): void {
    this.error = null;
    if (!this.validate()) {
      return;
    }
    this.isLoading = true;
    this.authService
      .signInWithEmail(this.credentials.email, this.credentials.password)
      .then((result) => {
      })
      .catch((error) => {
        this.error = this.errorMessages[error.code];
        this.isLoading = false;
      });
  }

  signInWithGoogle(): void {
    this.isLoading = true;
    this.credentials = { email: '', password: '' };
    this.authService
      .signInWithGoogle()
      .then((result) => {
      })
      .catch((error) => {
        console.log(error);
        this.isLoading = false;
      });
  }

  validate(): boolean {
    this.error = null;
    if (
      !this.credentials ||
      !this.credentials.email ||
      !this.credentials.password ||
      this.credentials.email.length === 0 ||
      this.credentials.password.length === 0
    ) {
      this.error = 'Fill in your credentials';
      return false;
    }
    return true;
  }
}
