import {Component} from '@angular/core';
import {FormControl} from "@angular/forms";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ApplicantsApi} from "../../../../../api/applicants.api";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {EApplicantGroupType, IApplicantGroup} from "../../../../../model/IApplicantGroup";
import {combineLatest, debounceTime, distinctUntilChanged, filter, map, Observable, of, startWith, Subject, switchMap, takeUntil} from "rxjs";
import {IApplicant} from "../../../../../../../../model/IApplicant";
import {IJob} from "../../../../../../../../model/IJob";
import {JobService} from "../../../../../services/job.service";
import {JobApplicantSelectorComponent} from "../../job-applicants-selector/job-applicant-selector.component";
import {ApplicantsService} from "../../../../../services/applicants.service";
import {ExistingOrUploadSelectorComponent} from "../../job-banner/existing-or-upload-selector/existing-or-upload-selector.component";
import {JobsApi} from "../../../../../api/jobs.api";
import {EStatus} from "../../../../../../../../model/enums/EResumeStatuses";
import {JobAiInstructionsComponent} from "../../job-ai-instructions/job-ai-instructions.component";
import {IEmailContact} from "../../../../../../../../model/IEmail";
import {EmailComposerComponent} from "../../../../../common/email-composer/email-composer.component";

@Component({
    selector: 'job-applicants', templateUrl: './job-applicants.component.html', styleUrls: ['./job-applicants.component.css']
})
export class JobApplicantsComponent {

    jobId: string | null | undefined;
    job: IJob | undefined;
    job$: Observable<IJob | undefined> | undefined;
    searchControl = new FormControl();
    applicants$: Observable<IApplicant[] | undefined> | undefined;
    filteredApplicants$: Observable<IApplicant[] | undefined> | undefined;
    groups: IApplicantGroup[] = [];
    selectedApplicantId: string | undefined;
    unsubscribe$ = new Subject<void>();

    constructor(
        private dialog: MatDialog,
        private applicantsApi: ApplicantsApi,
        private router: Router,
        private route: ActivatedRoute,
        private applicantsService: ApplicantsService,
        private jobsApi: JobsApi) {
        this.groups = [
            { type: EApplicantGroupType.PerfectFit, applicants: [] },
            { type: EApplicantGroupType.GreatFit, applicants: [] },
            { type: EApplicantGroupType.GoodFit, applicants: [] },
            { type: EApplicantGroupType.PotentialFit, applicants: [] },
            { type: EApplicantGroupType.NotAFit, applicants: [] },
            { type: EApplicantGroupType.Rejected, applicants: [] },
            { type: EApplicantGroupType.Processing, applicants: [] }
        ];
    }

    getNumberOfSelectedApplicants(): number {
        return this.applicantsService.selectedApplicants.length;
    }

    showBulkEmailComposer(applicants: IApplicant[]) {
        let selectedApplicantIds: string[] = this.applicantsService.selectedApplicants;
        let recipients: IEmailContact[] = [];

        // Create a recipient object for each selected applicant
        applicants.forEach( applicant => {
            if(selectedApplicantIds.includes(applicant.id)) {
                if(applicant.info?.contactInfo?.emails?.length) {
                    let recipient: IEmailContact = {
                        name: applicant.info.name ,
                        email: applicant.info.contactInfo.emails[0] //todo
                    }
                    recipients.push(recipient);
                }
            }
        });

        let dialogRef: MatDialogRef<EmailComposerComponent> | undefined;
        dialogRef = this.dialog.open(EmailComposerComponent, {});
        dialogRef.componentInstance.recipients = recipients;
    }

    toggleMultipleSelectMode() {
        this.applicantsService.toggleMultipleSelectMode();
    }

    isMultipleSelectModeEnabled() {
        return this.applicantsService.isMultipleSelectModeEnabled();
    }

    showAddApplicant() {
        let dialog = this.dialog.open(ExistingOrUploadSelectorComponent, {});
        dialog.componentInstance.job = this.job;
    }

    showAISettings() {
        let dialog = this.dialog.open(JobAiInstructionsComponent, {});
        dialog.componentInstance.job = this.job;
    }

    // This is to hide the AI-Settings banner
    updateAISettings(): void {
        if(!this.job) { return; }

        if(!this.job.userAiGuidelines) {
            this.job.userAiGuidelines = {
                scoringInstructions: "If the candidate doesn't match any of the technical skills give them a score below 50.",
                rejectReasons: "",
                isInitialized: false
            };
        } else {
            this.job.userAiGuidelines.isInitialized = true;
        }

        this.jobsApi.set(this.job.id, this.job).then( response => {

        }).catch( error => {
            console.log(error);
        })
    }

    ngOnInit() {

        this.route.paramMap.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
            this.jobId = params.get("jobId");
        });

        this.job$ = this.route.paramMap.pipe(takeUntil(this.unsubscribe$), switchMap((params) => {
            this.jobId = params.get("jobId");
            return this.jobId ? this.jobsApi.getOne(this.jobId) : of(undefined);
        }));
        this.job$.subscribe(job => {
            this.job = job;
        })

        this.applicants$ = this.route.paramMap.pipe(switchMap((params) => {
            let jobId = params.get("jobId");
            return jobId ? this.applicantsApi.getAll([{field: 'jobId', condition: '==', value: jobId}]) : of(undefined);
        }));
        this.applicantsService.applicants$ = this.applicants$;

        // Set the selected applicant based on the url parameter
        this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd), takeUntil(this.unsubscribe$), map(() => this.route), map((route) => {
            while (route.firstChild) route = route.firstChild;
            return route;
        }), filter((route) => route.outlet === "primary"), switchMap((route) => route.paramMap))
        .subscribe((params) => {
            const applicantId = params.get("applicantId");
            this.selectedApplicantId = applicantId ? applicantId : undefined;
        });

        this.filteredApplicants$ = combineLatest([this.applicants$ ?? of([]), this.searchControl.valueChanges.pipe(startWith(""), debounceTime(300), distinctUntilChanged())]).pipe(map(([applicants, searchTerm]: [IApplicant[] | undefined, string]) => {
            if (!applicants) {
                return undefined;
            }
            if (searchTerm) {
                return applicants.filter((applicant) => applicant?.info?.name
                .toLowerCase()
                .includes(searchTerm.toLowerCase()));
            }
            return applicants;
        }));

    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    protected readonly EStatus = EStatus;
}
