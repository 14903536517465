import {Component} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import {JobsApi} from "../../../api/jobs.api";
import {StoreService} from "../../../services/store.service";
import {EJobStatus} from "../../../../../../model/enums/EJobStatus";
import {IJob} from "../../../../../../model/IJob";
import {EStatus} from "../../../../../../model/enums/EResumeStatuses";
import {EPipelineStage} from "../../../../../../model/enums/EPipelineStage";
import {ActivatedRoute, Router} from "@angular/router";
import {Observable} from "rxjs";

@Component({
  selector: "job-form",
  templateUrl: "./job-form.component.html",
  styleUrls: ["./job-form.component.css"],
})
export class JobFormComponent {
  job: Omit<IJob, "id"> = {
    organizationId: this.store.organizationId!,
    title: "",
    description: "",
    jobStatus: EJobStatus.OPEN,
    applicantsCount: 0,
    creationTime: this.jobsApi.getServerTimestamp(),
    status: EStatus.STORED,
    pipeline: Object.values(EPipelineStage),
    userAiGuidelines: {
          scoringInstructions: "If the candidate doesn't match any of the technical skills give them a score below 50.",
          rejectReasons: "",
          isInitialized: false
    }
  };
  isLoading: boolean = false;
  isJobCreated: boolean = false; // Flag to denote a job has been created
  error: string | undefined;    // Error Message
  jobId: string | null = null; // The id of the newly created job
  job$: Observable<IJob|undefined> | undefined;

  constructor(
      private dialogRef: MatDialogRef<JobFormComponent>,
      private jobsApi: JobsApi,
      private store: StoreService,
      private router: Router,
  ) {}

  createJob(): void {
    if (!this.job.title || !this.job.description) {
      return;
    }
    this.isLoading = true;
    this.jobsApi
      .create(this.job)
      .then((result) => {
        this.jobId = result.id;
        this.job$ = this.jobsApi.getOne(this.jobId);
        this.job$.subscribe( (job) => {
          if(job?.status === EStatus.COMPLETED) {
            this.isLoading = false;
            this.isJobCreated = true;
          }
          if(job?.status === EStatus.ERROR) {
            this.isLoading = false;
            this.error = job.errorMessage;
          }
        })
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {

      });
  }

  goToJob(jobId: string): void {
    if (!jobId) return;
    this.dialogRef.close();
    this.router.navigate(['/organization', this.store.organizationId, 'jobs', jobId]); // Cannot use relative path from within a popup in Angular Material
  }

  ngOnInit() {
    console.log(this.router.url);
  }

}
