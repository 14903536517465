<div id="login">
  <div class="row center fill-space wrap">
    <div class="login-wrapper fill-space center">
      <div id="login-content" class="column" *ngIf="!isLoading">
        <div id="login-logo" class="center"><img src="assets/images/logo-vertical.svg" /></div>
        <div class="login-title">Create Account </div>
        <div class="column gap-1">
          <form id="credentials" class="column fill-space">
            <div class="form-field column fill-space space-bottom-1">
              <div class="form-field-label">Full Name</div>
              <div class="form-field-input fill-space">
                <input name="name" class="fill-space" type="text" autocomplete="username" [(ngModel)]="newUser.name" (keydown.enter)="register()" />
              </div>
            </div>
            <div class="form-field column fill-space space-bottom-1">
              <div class="form-field-label">Email</div>
              <div class="form-field-input fill-space">
                <input name="username" class="fill-space" type="text" autocomplete="username" [(ngModel)]="newUser.email" (keydown.enter)="register()" />
              </div>
            </div>
            <div class="form-field column fill-space">
              <div class="form-field-label">Password</div>
              <div class="form-field-input fill-space">
                <input name="password" class="fill-space" type="password" autocomplete="current-password" [(ngModel)]="newUser.password" (keydown.enter)="register()"/>
              </div>
            </div>
          </form>
          <div id="login-error" class="form-error" *ngIf="error">{{ error }}</div>
          <div id="login-button" class="" (click)="register()">Create Account</div>

        </div>

        <div class="terms">
          By continuing, you agree to HireFox's
          <a href="https://hirefox.ai/terms-of-service" target="_blank"> Terms of Service </a> and
          <a href="https://hirefox.ai/privacy-policy" target="_blank"> Privacy Policy </a>
        </div>

        <div class="or-wrapper center">
          <div class="column center fill-space"> <div class="horizontal-line"> </div> </div>
          <div class="or center"> OR </div>
          <div class="column center fill-space"> <div class="horizontal-line"> </div> </div>
        </div>

        <div class="register-link fill-space column gap-1" routerLink="/login">
          <div> Already Have An Account ? </div>
          <div class="register-button">  Sign In </div>
        </div>





      </div>
      <div class="fill-space column center" *ngIf="isLoading">
        <loader loaderType="badge" *ngIf="isLoading"></loader>
        <h4>Creating Your Account. Please Wait...</h4>
      </div>
    </div>
    <div class="slider-wrapper fill-space column">
      <slider [slides]="slides"></slider>
    </div>
  </div>

</div>


