import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Auth } from "@angular/fire/auth";
import { OrganizationsApi } from "../../../api/organizations.api";

@Component({
  selector: "user-organization-form",
  templateUrl: "./user-organization-form.component.html",
  styleUrls: ["./user-organization-form.component.css"],
})
export class UserOrganizationFormComponent {
  isLoading: boolean = false;
  organizationName: string | undefined;

  constructor(
    private dialogRef: MatDialogRef<UserOrganizationFormComponent>,
    private auth: Auth,
    private organizationService: OrganizationsApi,
  ) {}

  createOrganization() {
    if (!this.organizationName) {
      return;
    }

    this.isLoading = true;
    this.organizationService
      .create(this.auth.currentUser?.uid!, this.organizationName)
      .then((response) => {
          if(response) {
              this.dialogRef.close();
              this.changeOrganization(response.id);
          }

      })
      .catch((error) => {
        console.error(error);
        this.isLoading = false;
      });
  }

    changeOrganization(organizationId: string): void {
        window.location.href = `/organization/${organizationId}`;
    }
}
