<div id="login">
  <div class="row center fill-space wrap">
    <div class="login-wrapper fill-space center">
      <div id="login-content" class="column" *ngIf="!isLoading">
        <div id="login-logo" class="center"><img src="assets/images/logo-vertical.svg" /></div>
        <div class="login-title">Sign In</div>
        <div class="column gap-1">
          <form>
            <div id="credentials" class="column">
              <div class="form-field column fill-space space-bottom-1">
                <div class="form-field-label">Email</div>
                <div class="form-field-input fill-space">
                  <input
                    name="username"
                    class="fill-space"
                    type="text"
                    autocomplete="username"
                    placeholder="Enter your email"
                    [(ngModel)]="credentials.email"
                    (keydown.enter)="signInWithEmail()" />
                </div>
              </div>
              <div class="form-field column">
                <div class="form-field-label">Password</div>
                <div class="form-field-input fill-space">
                  <input
                    name="password"
                    class="fill-space"
                    type="password"
                    placeholder="Enter your password"
                    autocomplete="current-password"
                    [(ngModel)]="credentials.password"
                    (keydown.enter)="signInWithEmail()" />
                </div>
              </div>
            </div>
          </form>
          <div id="login-error" class="form-error" *ngIf="error">{{ error }}</div>
          <div id="login-button" class="" (click)="signInWithEmail()">Sign In</div>
          <div class="register-link space-top-1 fill-space" routerLink="/forgot-password">
            Forgot Your Password ? &nbsp; <span class="semibold flavor">  Password Reset </span>
          </div>
        </div>

        <div class="or-wrapper center">
          <div class="column center fill-space"> <div class="horizontal-line"> </div> </div>
          <div class="or center"> OR </div>
          <div class="column center fill-space"> <div class="horizontal-line"> </div> </div>
        </div>

        <div class="register-link fill-space column gap-1" routerLink="/register">
          <div> Don't Have An Account ? </div>
          <div class="register-button">  Create Account </div>
        </div>


        <!-- TODO: Need to check the registration process when a user signs in for the first time using Google
        <div class="social-divider"></div>

        <div class="social-button fill-space" (click)="signInWithGoogle()">
          <div class="social-button-icon"> <img src="/assets/images/google.svg" /> </div>
          <div class="social-button-text"> Sign In With Google </div>
        </div>
        -->
      </div>
      <div class="fill-space column center" *ngIf="isLoading">
        <loader loaderType="badge" *ngIf="isLoading"></loader>
        <h4>Authenticating credentials...</h4>
      </div>
    </div>
    <div class="slider-wrapper fill-space">
      <slider [slides]="slides"></slider>
    </div>
  </div>

</div>
