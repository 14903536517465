import { Component } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";
import { UtilService } from "../../services/util.service";
import { UserCredential } from "@angular/fire/auth";
import { UsersApi } from "../../api/users.api";
import { IUser } from "../../../../../model/IUser";

interface IRegistrationUser {
  name: string;
  email: string;
  password: string;
}

@Component({
  selector: "register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent {
  newUser: IRegistrationUser = { name: "", email: "", password: "" };
  error: string | null = null;
  isLoading: boolean = false;
  errorMessages: { [key: string]: string } = {
    "auth/invalid-email": "Wrong Username Or Password!",
    "auth/wrong-password": "Wrong Username Or Password!",
    "auth/internal-error":
        "Problem With Authentication Server. Please contact support at support@hirefox.ai",
  };

  slides: string[] = ["slide-1.png", "slide-2.png", "slide-3.png", "slide-4.png"];

  constructor(
    private authService: AuthService,
    private router: Router,
    private utilService: UtilService,
    private usersApi: UsersApi,
  ) {}

  async register(): Promise<void> {
    if (!this.validate()) {
      return;
    }

    this.isLoading = true;
    try {

      let firebaseUser: UserCredential = await this.authService.signUpWithEmail(this.newUser.email, this.newUser.password);

      let user: Omit<IUser, 'id'> = {
        email: this.newUser.email,
        emailVerified: true, // TODO: implement email verification
        name: this.newUser.name,
        favoriteOrganization: "",
        organizations: [],
      };

      await this.usersApi.set(firebaseUser.user.uid, user);
      this.isLoading = false;
      this.router.navigate(["/"]).then();
    } catch (error:any) {
      this.isLoading = false;
      this.error = this.errorMessages[error.code];
    }
  }

  validate(): boolean {
    this.error = null;

    if (!this.newUser.name || this.newUser.name.trim() === "") {
      this.error = "Enter your full name";
      return false;
    }
    if (
      !this.newUser.email ||
      !this.utilService.isValidEmail(this.newUser.email)
    ) {
      this.error = "Enter a valid Email";
      return false;
    }
    if (
      !this.newUser.password ||
      this.newUser.password.trim() === "" ||
      this.newUser.password.length < 6
    ) {
      this.error = "Enter a valid Password";
      return false;
    }
    return true;
  }
}
