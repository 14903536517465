<div class="slider fill-space column center">
  <div class="fill-space"></div>
  <div class="slider-content">
    <div class="slide">
      <img class="slider-image" [src]="'assets/images/slider/'+slides[currentSlide]"/>
    </div>
  </div>
  <div class="fill-space"></div>
  <div class="slider-controls center gap-2">
    <!--<div class="slider-arrow" (click)="previousSlide()"> <img src="assets/images/slider-left.svg"/> </div>-->
    <div class="slider-state gap-1">
      <div
          class="slider-state-shape cursor"
          [class]="{'slider-state-shape-selected': currentSlide === index }"
          *ngFor="let s of slides; let index = index;"
          (click)="goToSlide(index)">
      </div>
    </div>
    <!--<div class="slider-arrow" (click)="nextSlide()"> <img src="assets/images/slider-right.svg"/> </div>-->
  </div>

</div>
