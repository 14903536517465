import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations';

@Component({
  selector: 'slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css'],
})
export class SliderComponent implements OnInit {
  @Input() slides: string[] = [];
  currentSlide: number = 0;
  private fadeOutPlayer: AnimationPlayer | null = null;
  private fadeInPlayer: AnimationPlayer | null = null;

  constructor(private animationBuilder: AnimationBuilder, private el: ElementRef) {}

  nextSlide(): void {
    this.triggerFadeOut(() => {
      // Once fade-out is done, move to the next slide
      this.currentSlide = (this.currentSlide + 1) % this.slides.length;
      this.triggerFadeIn();
    });
  }

  previousSlide(): void {
    this.triggerFadeOut(() => {
      // Once fade-out is done, move to the previous slide
      this.currentSlide = (this.currentSlide - 1 + this.slides.length) % this.slides.length;
      this.triggerFadeIn();
    });
  }

  goToSlide(newSlide: number): void {
    this.triggerFadeOut(() => {
      // Once fade-out is done, move to the previous slide
      this.currentSlide = newSlide;
      this.triggerFadeIn();
    });
  }

  ngOnInit() {
    interval(5000).subscribe(() => {
      this.nextSlide();
    });
  }

  triggerFadeOut(onDone: () => void): void {
    // Get the current slide element
    const slideElement = this.el.nativeElement.querySelector('.slider-image');

    if (this.fadeOutPlayer) {
      this.fadeOutPlayer.destroy(); // Destroy previous animation if it exists
    }

    // Build a fade-out animation
    const fadeOutAnimation = this.animationBuilder.build([
      style({ opacity: 1 }),
      animate('500ms linear', style({ opacity: 0 })),
    ]);

    // Create and play the animation
    this.fadeOutPlayer = fadeOutAnimation.create(slideElement);
    this.fadeOutPlayer.onDone(() => {
      onDone(); // Call the callback function once animation is done
    });
    this.fadeOutPlayer.play();
  }

  triggerFadeIn(): void {
    // Get the current slide element
    const slideElement = this.el.nativeElement.querySelector('.slider-image');

    if (this.fadeInPlayer) {
      this.fadeInPlayer.destroy(); // Destroy previous animation if it exists
    }

    // Build a fade-in animation
    const fadeInAnimation = this.animationBuilder.build([
      style({ opacity: 0 }),
      animate('500ms linear', style({ opacity: 1 })),
    ]);

    // Create and play the animation
    this.fadeInPlayer = fadeInAnimation.create(slideElement);
    this.fadeInPlayer.play();
  }
}
